/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const Map = ({ title }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {title ? (
          <h2 className="text-center">{title}</h2>
        ) : (
          <h2 className="text-center">
            We Cover West Valley, from Ogden To Provo and Park City to Tooele
          </h2>
        )}
        {rpdata?.dbPrincipal?.location.length > 2 ? (
          <ul className="pt-4 pb-4 md:p-0 flex flex-wrap justify-between md:justify-center items-center ">
            {rpdata?.dbPrincipal?.location?.map((item, index) => {
              return (
                <Link to="/contact" key={index}>
                  <li className="py-2 px-3 flex items-center">
                    <FiMapPin fontSize={25} />
                    <span className="pl-2">{item.address}</span>
                  </li>
                </Link>
              );
            })}
          </ul>
        ) : null}
        <div className="w-[90%] mx-auto">
          <div
          className="md:h-[800px] h-[300px] md:bg-contain bg-contain bg-no-repeat"
            style={{
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Maps%2FMapAdansAutoGlass.png?alt=media&token=8a73df5c-aff9-4f26-9b23-db4618398a64)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Map;
